import { FC, useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form, FormikProps } from "formik";
import {
  Button,
  MessageField,
  FormButtons,
  FormField,
  notification,
  Modal,
  Label,
  Switch,
} from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { updateMarketoAccount, UpdateMarketoAccountData } from "api";
import { MarketoAccountModel } from "models";
import { validationSchema, initialValues } from "./update-marketo.constants";

import styles from "./update-marketo.module.scss";

export type UpdateMarketoAccountProps = {
  isOpen: boolean;
  close: VoidFunction;
  account: MarketoAccountModel | null;
  onUpdateSuccess: (value: MarketoAccountModel) => void;
};

export const UpdateMarketoAccount: FC<UpdateMarketoAccountProps> = ({ isOpen, account, close, onUpdateSuccess }) => {
  const [clientSecretEnabled, setClientSecretEnabled] = useState<boolean>(false);

  const { submit, error, onSubmitSuccess, onSubmitError } = useSubmit(updateMarketoAccount);
  onSubmitSuccess(({ response }) => {
    onUpdateSuccess(response);
    close();
    notification.success("Marketo account updated!", "Marketo account has been successfully updated.");
  });
  onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the Marketo account.");
  });

  useDidUpdate(() => {
    setClientSecretEnabled(false);
  }, [isOpen]);

  const handleSwitchToggle =
    (formikProps: FormikProps<Partial<UpdateMarketoAccountData>>) => (value: string, checked: boolean) => {
      setClientSecretEnabled(checked);
      if (!checked) {
        formikProps.setFieldValue("clientSecret", "");
        formikProps.setFieldTouched("clientSecret", false, false);
      }
    };

  const handleSubmit = async (values: UpdateMarketoAccountData) => {
    if (account) {
      const data = { ...values };
      if (!clientSecretEnabled) delete data.clientSecret;

      await submit({
        data,
        params: { accountId: account.id },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} setClose={close}>
      <Formik
        initialValues={initialValues(account)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(clientSecretEnabled)}
        enableReinitialize
      >
        {(props) => (
          <Form>
            <div className={styles.title}>Update {account?.name} account</div>
            <p className={styles.description}>
              If the switch is checked, a new client secret will be required. When the switch is not checked, the client
              secret that was added earlier will be used.
            </p>

            <FormField type="text" name="name" label="Account name" disableFloatingLabel required />

            <div className={styles.row}>
              <FormField
                type="text"
                name="clientId"
                label="Client ID"
                disableFloatingLabel
                required
                className={styles.clientIdField}
              />
              <div>
                <div className={styles.labelRow}>
                  <Label text="Client secret" isInputLabel />
                  <Switch
                    value="test"
                    disableError
                    checked={clientSecretEnabled}
                    onChange={handleSwitchToggle(props)}
                  />
                </div>
                <FormField
                  type="text"
                  name="clientSecret"
                  placeholder={clientSecretEnabled ? "Provide a new client secret" : "Use the same client secret"}
                  disableFloatingLabel
                  disabled={!clientSecretEnabled}
                />
              </div>
            </div>

            <FormField type="text" name="endpoint" label="Endpoint" disableFloatingLabel required />

            <MessageField message={error?.message || ""} />

            <FormButtons className={styles.buttons}>
              <Button appearance="secondary" onClick={close}>
                Cancel
              </Button>
              <Button type="submit">Submit</Button>
            </FormButtons>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
