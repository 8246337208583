import { ColumnsType } from "types";

export const TABLE_NAME = "insider-by-accounts";

export const accountsColumns: ColumnsType = [
  { label: "Account", queryKey: "name" },
  { label: "Integration" },
  { label: "Succeeded", required: true, queryKey: "completed" },
  { label: "Failed", required: true, queryKey: "failed" },
  { label: "Active", required: true, queryKey: "active" },
];
