import React, { useState } from "react";
import { useFetch } from "@hyper-fetch/react";
import { Pagination, Search, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useDebounce } from "@better-hooks/performance";

import { UnsubscribeJobModel } from "models";
import { getInitialStorageFilters } from "utils";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { initialFilters, unsubscribeValuesColumns, TABLE_NAME } from "./unsubscribe-values.constants";
import { getUnsubscribeJobValues } from "api";
import { UnsubscribeValueItem } from "./unsubscribe-value-item/unsubscribe-value-item";
import { TableError, TableNoContent } from "components";

import styles from "./unsubscribe-values.module.scss";

type SuppressionValuesProps = {
  job: UnsubscribeJobModel;
};

export const UnsubscribeValues: React.FC<SuppressionValuesProps> = ({ job }) => {
  const { debounce } = useDebounce({ delay: 1200 });

  const { state, currentState, setValue } = useFilters<BasePaginationQuery & BaseSearchQuery>({
    ...getInitialStorageFilters<BasePaginationQuery & BaseSearchQuery>(TABLE_NAME, initialFilters),
    isStateBased: true,
  });

  const {
    data: jobUnsubscribes,
    loading,
    error,
    refetch,
  } = useFetch(
    getUnsubscribeJobValues.setParams({ jobId: job.id }).setQueryParams({ ...state, "order[value]": "asc" }),
  );

  const pagination = usePagination({
    listPayload: jobUnsubscribes,
    isStateBased: true,
    initialState: currentState,
  });

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            <h2 className={styles.label}>Unsubscribe values</h2>
            <p className={styles.subtitle}>All emails that were unsubscribed.</p>
          </div>

          <div className={styles.filtersWrapper}>
            <div className={styles.searchWrapper}>
              <Search
                searchValue={state.search}
                setSearch={(value) => {
                  debounce(() => setValue("search")(value));
                }}
                onClearClick={() => setValue("search")("")}
                width="small"
                className={styles.search}
              />
            </div>
          </div>
        </div>

        <div className={styles.content}>
          <Table
            entityName={TABLE_NAME}
            columns={unsubscribeValuesColumns}
            list={jobUnsubscribes?.data}
            error={error?.message}
            refresh={() => refetch()}
            loading={loading}
            resetColumnsOnMount={false}
            className={styles.table}
            contentClassName={styles.tableContent}
            customNoContent={<TableNoContent />}
            customError={<TableError description={error?.message} />}
            row={(unsubscribeValue, index) => <UnsubscribeValueItem value={unsubscribeValue} index={index} />}
          />
        </div>
      </div>

      <div className={styles.pagination}>
        <Pagination
          {...pagination}
          onPageChange={(page, offset) => {
            pagination.onPageChange(page, offset);
            setValue("offset")(offset);
          }}
          onElementsPerPageChange={(value) => {
            pagination.onElementsPerPageChange(value);
            setValue("limit")(value);
          }}
        />
      </div>
    </div>
  );
};
