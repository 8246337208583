import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { BasePaginationQuery, BaseSearchQuery, ColumnsType } from "types";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "constants/query.constants";

export const TABLE_NAME = "iterable-value-projects";

export const initialFilters: UseFiltersProps<BasePaginationQuery & BaseSearchQuery> = {
  initialState: {
    search: "",
    offset: DEFAULT_OFFSET,
    limit: DEFAULT_LIMIT,
  },
};

export const greenArrowSuppressionColumns: ColumnsType = [
  { label: "Account name", required: true, queryKey: "name" },
  { label: "Integration" },
  { label: "Status", required: true, queryKey: "status" },
  { label: "" },
];
