import { RouteObject } from "react-router-dom";

import { PageRoute } from "components";
import { PermissionTuple } from "models";
import {
  LoginPage,
  LogoutPage,
  NoAccessPage,
  DashboardPage,
  CreateSuppressionPage,
  UsersListPage,
  JobsListPage,
  JobDetailsPage,
  NotFoundPage,
  AuditLogsListPage,
  SettingsPage,
  IterableAccountsPage,
  IterableProjectsPage,
  SalesforceBusinessUnitsPage,
  SalesforceDataExtensionsPage,
  OptizmoCompaniesPage,
  OptizmoClientsPage,
  OptizmoOffersPage,
  Md5ExportPage,
  Md5JobsListPage,
  Md5ImportPage,
  CreateUnsubPage,
  UnsubscribeJobDetailsPage,
  UnsubscribeJobsListPage,
  RemarketyAccountsPage,
  AutocleaningListPage,
  MarketoAccountsPage,
  GreenArrowAccountsPage,
  InsiderAccountsPage,
} from "pages";
import {
  DEFAULT_PAGE,
  LOGIN_PAGE,
  LOGOUT_PAGE,
  CREATE_SUPPRESSION_PAGE,
  USERS_LIST_PAGE,
  NO_ACCESS_PAGE,
  ITERABLE_ACCOUNTS_LIST_PAGE,
  REMARKETY_ACCOUNTS_LIST_PAGE,
  DASHBOARD_PAGE,
  JOBS_LIST_PAGE,
  JOB_DETAILS_PAGE,
  NOT_FOUND_PAGE,
  AUDIT_LOGS_PAGE,
  SETTINGS_PAGE,
  ITERABLE_PROJECTS_LIST_PAGE,
  SALESFORCE_DATA_EXTENSIONS_LIST_PAGE,
  SALESFORCE_BUSINESS_UNITS_LIST_PAGE,
  OPTIZMO_COMPANIES_LIST_PAGE,
  OPTIZMO_CLIENTS_LIST_PAGE,
  OPTIZMO_OFFERS_LIST_PAGE,
  MD5_JOBS_PAGE,
  MD5_IMPORT_PAGE,
  MD5_EXPORT_PAGE,
  CREATE_UNSUB_PAGE,
  UNSUBSCRIBE_JOBS_LIST_PAGE,
  UNSUBSCRIBE_JOB_DETAILS_PAGE,
  AUTOCLEANING_LIST_PAGE,
  MARKETO_ACCOUNTS_LIST_PAGE,
  GREENARROW_ACCOUNTS_LIST_PAGE,
  INSIDER_ACCOUNTS_LIST_PAGE,
} from "constants/routes.constants";

export type Route = RouteObject & {
  name: string;
  path: string;
  auth: boolean;
  children?: RouteObject[];
  showNavigation?: boolean;
  permission?: PermissionTuple;
  disabled?: boolean;
  description?: string;
  hideGlobalNavbar?: boolean;
  hidePageName?: boolean;
  navbarClassName?: string;
};

export const appRoutes: Route[] = [
  { ...LOGIN_PAGE, element: <LoginPage /> },
  { ...LOGOUT_PAGE, element: <LogoutPage /> },
  { ...NO_ACCESS_PAGE, element: <NoAccessPage /> },
  { ...DASHBOARD_PAGE, element: <DashboardPage /> },
  { ...DEFAULT_PAGE, element: <DashboardPage /> },

  { ...JOBS_LIST_PAGE, element: <JobsListPage /> },
  { ...JOB_DETAILS_PAGE, element: <JobDetailsPage /> },

  { ...AUTOCLEANING_LIST_PAGE, element: <AutocleaningListPage /> },

  { ...ITERABLE_ACCOUNTS_LIST_PAGE, element: <IterableAccountsPage /> },
  { ...ITERABLE_PROJECTS_LIST_PAGE, element: <IterableProjectsPage /> },

  { ...SALESFORCE_BUSINESS_UNITS_LIST_PAGE, element: <SalesforceBusinessUnitsPage /> },
  { ...SALESFORCE_DATA_EXTENSIONS_LIST_PAGE, element: <SalesforceDataExtensionsPage /> },

  { ...OPTIZMO_COMPANIES_LIST_PAGE, element: <OptizmoCompaniesPage /> },
  { ...OPTIZMO_CLIENTS_LIST_PAGE, element: <OptizmoClientsPage /> },
  { ...OPTIZMO_OFFERS_LIST_PAGE, element: <OptizmoOffersPage /> },

  { ...REMARKETY_ACCOUNTS_LIST_PAGE, element: <RemarketyAccountsPage /> },

  { ...MARKETO_ACCOUNTS_LIST_PAGE, element: <MarketoAccountsPage /> },

  { ...GREENARROW_ACCOUNTS_LIST_PAGE, element: <GreenArrowAccountsPage /> },

  { ...INSIDER_ACCOUNTS_LIST_PAGE, element: <InsiderAccountsPage /> },

  { ...MD5_JOBS_PAGE, element: <Md5JobsListPage /> },
  { ...MD5_IMPORT_PAGE, element: <Md5ImportPage /> },

  { ...CREATE_SUPPRESSION_PAGE, element: <CreateSuppressionPage /> },
  { ...MD5_EXPORT_PAGE, element: <Md5ExportPage /> },

  { ...CREATE_UNSUB_PAGE, element: <CreateUnsubPage /> },
  { ...UNSUBSCRIBE_JOBS_LIST_PAGE, element: <UnsubscribeJobsListPage /> },
  { ...UNSUBSCRIBE_JOB_DETAILS_PAGE, element: <UnsubscribeJobDetailsPage /> },

  { ...SETTINGS_PAGE, element: <SettingsPage /> },
  { ...USERS_LIST_PAGE, element: <UsersListPage /> },
  { ...AUDIT_LOGS_PAGE, element: <AuditLogsListPage /> },
  { ...NOT_FOUND_PAGE, element: <NotFoundPage /> },
];

export const addRouteWrapper = (routes: Route[]): RouteObject[] => {
  return routes.map(({ element, ...params }) => ({
    ...params,
    element: <PageRoute {...params} element={element} />,
  }));
};

export const routes = addRouteWrapper(appRoutes);
