import { object, string, Schema } from "yup";

import { UpdateMarketoAccountData } from "api";
import { MarketoAccountModel } from "models";

export const validationSchema = (clientSecretEnabled: boolean): Schema<UpdateMarketoAccountData> =>
  object().shape({
    name: string().required("Name is required"),
    clientId: string().required("Client ID is required"),
    endpoint: string().required("Endpoint is required"),
    clientSecret: clientSecretEnabled ? string().required("Client secret is required") : string(),
  });

export const initialValues = (account: MarketoAccountModel | null): UpdateMarketoAccountData => ({
  name: account?.name || "",
  clientId: account?.clientId || "",
  clientSecret: "",
  endpoint: account?.endpoint || "",
});
