import { Route } from "config/routes.config";
import { PermissionTuple } from "models";

export const LOGIN_PAGE: Route = {
  path: "/login",
  name: "Login",
  auth: false,
} as const;

export const DASHBOARD_PAGE: Route = {
  path: "/dashboard",
  name: "Dashboard",
  auth: true,
  showNavigation: true,
  permission: ["read", "dashboard"] as PermissionTuple,
  navbarClassName: "dashboard-navbar",
} as const;

export const AUTOCLEANING_LIST_PAGE: Route = {
  path: "/autocleaning",
  name: "Autocleaning",
  description: "List of jobs added automatically.",
  auth: true,
  showNavigation: true,
  permission: ["read", "autocleaning"] as PermissionTuple,
  navbarClassName: "autocleaning-navbar",
} as const;

export const JOBS_LIST_PAGE: Route = {
  path: "/jobs",
  name: "Jobs",
  description: "List of jobs with added suppressions. Click on job to see all the details.",
  auth: true,
  showNavigation: true,
  permission: ["read", "jobs"] as PermissionTuple,
  navbarClassName: "jobs-list-navbar",
} as const;

export const JOB_DETAILS_PAGE: Route = {
  path: "/jobs/:jobId",
  name: "Suppression job details",
  auth: true,
  showNavigation: true,
  hideGlobalNavbar: true,
  permission: ["read", "jobs"] as PermissionTuple,
} as const;

export const ITERABLE_ACCOUNTS_LIST_PAGE: Route = {
  path: "/accounts",
  name: "Iterable accounts",
  auth: true,
  showNavigation: true,
  permission: ["read", "integrations"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;
export const ITERABLE_PROJECTS_LIST_PAGE: Route = {
  path: "/account/:accountId/projects",
  name: "Iterable projects",
  auth: true,
  showNavigation: true,
  permission: ["read", "integrations"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;

export const SALESFORCE_BUSINESS_UNITS_LIST_PAGE: Route = {
  path: "/business-units",
  name: "Salesforce business units",
  auth: true,
  showNavigation: true,
  permission: ["read", "integrations"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;
export const SALESFORCE_DATA_EXTENSIONS_LIST_PAGE: Route = {
  path: "/business-unit/:businessUnitId/data-extensions",
  name: "Salesforce data extensions",
  auth: true,
  showNavigation: true,
  permission: ["read", "integrations"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;

export const OPTIZMO_COMPANIES_LIST_PAGE: Route = {
  path: "/companies",
  name: "Optizmo companies",
  auth: true,
  showNavigation: true,
  permission: ["read", "integrations"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;
export const OPTIZMO_CLIENTS_LIST_PAGE: Route = {
  path: "/companies/:companyId/clients",
  name: "Optizmo clients",
  auth: true,
  showNavigation: true,
  permission: ["read", "integrations"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;
export const OPTIZMO_OFFERS_LIST_PAGE: Route = {
  path: "/companies/:companyId/clients/:clientId/offers",
  name: "Optizmo offers",
  auth: true,
  showNavigation: true,
  permission: ["read", "integrations"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;

export const REMARKETY_ACCOUNTS_LIST_PAGE: Route = {
  path: "/remarkety-accounts",
  name: "Remarkety accounts",
  auth: true,
  showNavigation: true,
  permission: ["read", "integrations"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;

export const MARKETO_ACCOUNTS_LIST_PAGE: Route = {
  path: "/marketo-accounts",
  name: "Marketo accounts",
  auth: true,
  showNavigation: true,
  permission: ["read", "integrations"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;

export const GREENARROW_ACCOUNTS_LIST_PAGE: Route = {
  path: "/greenarrow-accounts",
  name: "GreenArrow accounts",
  auth: true,
  showNavigation: true,
  permission: ["read", "integrations"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;

export const INSIDER_ACCOUNTS_LIST_PAGE: Route = {
  path: "/insider-accounts",
  name: "Insider accounts",
  auth: true,
  showNavigation: true,
  permission: ["read", "integrations"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;

export const BUSINESS_UNIT_DETAILS_PAGE: Route = {
  path: "/business-unit/:businessUnitId",
  name: "Salesforce integration details",
  auth: true,
  showNavigation: true,
  permission: ["read", "integrations"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;

export const USERS_LIST_PAGE: Route = {
  path: "/users",
  name: "Users",
  description: "List of all users with their roles.",
  auth: true,
  showNavigation: true,
  permission: ["read", "users"] as PermissionTuple,
  hideGlobalNavbar: true,
} as const;

export const AUDIT_LOGS_PAGE: Route = {
  path: "/audit",
  name: "Audit events",
  description:
    "List of all events across the application. If it's an event that involves cleaning/suppressing emails, the file will be available to download when you click on the item",
  auth: true,
  showNavigation: true,
  permission: ["read", "audit-logs"] as PermissionTuple,
  navbarClassName: "audit-logs-navbar",
} as const;

export const CREATE_SUPPRESSION_PAGE: Route = {
  path: "/create-suppression",
  name: "Create suppression",
  description: "Create a new suppression. Add emails manually or upload a file.",
  auth: true,
  showNavigation: true,
  permission: ["create", "suppressions"] as PermissionTuple,
  navbarClassName: "create-suppression-navbar",
} as const;

export const CREATE_UNSUB_PAGE: Route = {
  path: "/unsubscribe",
  name: "Unsubscribe",
  description: "Unsubscribe from lists. Add emails manually or upload a file.",
  auth: true,
  showNavigation: true,
  permission: ["create", "unsub"] as PermissionTuple,
  navbarClassName: "create-unsub-navbar",
} as const;

export const UNSUBSCRIBE_JOBS_LIST_PAGE: Route = {
  path: "/unsubscribe-jobs",
  name: "Jobs",
  description: "List of unsubscribe jobs. Click on a job to see all the details.",
  auth: true,
  showNavigation: true,
  permission: ["read", "unsub-jobs"] as PermissionTuple,
  navbarClassName: "jobs-list-navbar",
} as const;

export const UNSUBSCRIBE_JOB_DETAILS_PAGE: Route = {
  path: "/unsubscribe-jobs/:jobId",
  name: "Unsubscribe job details",
  auth: true,
  showNavigation: true,
  hideGlobalNavbar: true,
  permission: ["read", "unsub-jobs"] as PermissionTuple,
} as const;

export const MD5_EXPORT_PAGE: Route = {
  path: "/export",
  name: "Export",
  description: "Create a new optizmo export.",
  auth: true,
  showNavigation: true,
  permission: ["create", "export"] as PermissionTuple,
  navbarClassName: "md5-export-navbar",
} as const;

export const MD5_JOBS_PAGE: Route = {
  path: "/md5-jobs",
  name: "Jobs",
  description: "List of all MD5 jobs.",
  auth: true,
  showNavigation: true,
  permission: ["read", "md5"] as PermissionTuple,
  navbarClassName: "md5-job-navbar",
} as const;

export const MD5_IMPORT_PAGE: Route = {
  path: "/md5-import",
  name: "Import",
  description: "Import MD5",
  auth: true,
  showNavigation: true,
  permission: ["create", "md5"] as PermissionTuple,
  navbarClassName: "md5-import-navbar",
} as const;

export const SETTINGS_PAGE: Route = {
  path: "/settings",
  name: "Settings",
  description: "Update your account settings",
  auth: true,
  showNavigation: true,
  navbarClassName: "settings-navbar",
} as const;

export const DEFAULT_PAGE: Route = {
  ...DASHBOARD_PAGE,
  path: "/",
} as const;

export const LOGOUT_PAGE: Route = {
  path: "/logout",
  name: "Logout",
  auth: false,
  showNavigation: false,
} as const;

export const NO_ACCESS_PAGE: Route = {
  path: "/no-access",
  name: "No access",
  auth: true,
  showNavigation: true,
  hideGlobalNavbar: true,
} as const;

export const NOT_FOUND_PAGE: Route = {
  path: "*",
  name: "404 - Not found",
  auth: true,
  showNavigation: true,
  hideGlobalNavbar: true,
} as const;
