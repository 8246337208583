import React from "react";
import { Drawer, Nullable } from "@epcnetwork/core-ui-kit";

import { JobItemStatus } from "components";

import styles from "./error-drawer.module.scss";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  errorToPreview: Nullable<string>;
}

export const ErrorDrawer: React.FC<Props> = ({ isOpen, handleClose, errorToPreview }) => {
  const getError = (): string => {
    if (!errorToPreview) return "";

    try {
      return JSON.parse(errorToPreview);
    } catch (error) {
      return errorToPreview;
    }
  };

  const parsed = getError();

  return (
    <Drawer isOpen={isOpen} setClose={handleClose} contentClassName={styles.drawer}>
      {errorToPreview && (
        <div className={styles.container}>
          <JobItemStatus status="failed" />
          <div className={styles.jsonWrapper}>
            <pre className={styles.json}>{JSON.stringify(parsed, null, 2)}</pre>
          </div>
        </div>
      )}
    </Drawer>
  );
};
