import React, { useState } from "react";
import { FormField, Label, SelectOption } from "@epcnetwork/core-ui-kit";
import { useFetch } from "@hyper-fetch/react";
import { useFormikContext } from "formik";

import { getActiveBusinessUnits, getActiveLists, UnsubscribeFormData, SalesforceUnsubscribeData } from "api";

import styles from "../unsub-list-select.module.scss";

export const SalesforceUnsub: React.FC = () => {
  const { values } = useFormikContext<UnsubscribeFormData>();

  const salesforceData = values.data.properties as SalesforceUnsubscribeData;

  const [businessUnitsOptions, setBusinessUnitsOptions] = useState<SelectOption<number>[]>([]);
  const [listsOptions, setListsOptions] = useState<SelectOption<number>[]>([]);

  const businessUnits = useFetch(getActiveBusinessUnits);
  businessUnits.onSuccess(({ response }) => {
    const options: SelectOption<number>[] = response.map((businessUnits) => ({
      label: businessUnits.name,
      value: businessUnits.id,
    }));
    setBusinessUnitsOptions(options);
  });

  const activeLists = useFetch(
    getActiveLists.setParams({ businessUnitId: (salesforceData.businessUnitId || 0).toString() }),
    {
      disabled: !salesforceData.businessUnitId,
      dependencies: [salesforceData.businessUnitId],
    },
  );
  activeLists.onSuccess(({ response }) => {
    const options: SelectOption<number>[] = response.map((dataExtension) => ({
      label: dataExtension.ListName,
      value: dataExtension.ID,
    }));
    setListsOptions(options);
  });

  return (
    <div className={styles.container}>
      <div>
        <Label text="Select a business unit" isInputLabel />
        <p className={styles.description}>Select the business unit you want to unsubscribe from</p>
        <FormField
          type="select"
          label="Business unit"
          name="data.properties.businessUnitId"
          options={businessUnitsOptions}
          asyncOptions={{ loading: businessUnits.loading }}
        />
      </div>

      <div className={styles.wrapper}>
        <div>
          <Label text="Select mode" isInputLabel />
          <p className={styles.description}>Select a single list from the business unit</p>
        </div>

        <FormField
          type="select"
          label="List"
          name="data.properties.listId"
          options={listsOptions}
          disabled={!salesforceData.businessUnitId}
          asyncOptions={{ loading: activeLists.loading }}
          className={styles.input}
        />
      </div>
    </div>
  );
};
