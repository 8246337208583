import { List } from "models";
import { client } from "api/client.api";
import {
  DashboardGeneralData,
  DataExtensionsStats,
  GreenArrowAccountsStats,
  InsiderAccountsStats,
  MarketoAccountsStats,
  ProjectsStats,
  RemarketyAccountsStats,
} from "api/dashboard/dashboard.types";

export const getDashboardGeneral = client.createRequest<DashboardGeneralData>()({
  method: "GET",
  endpoint: "/dashboard/general",
  options: { timeout: 0 },
});

export const getGroupedProjects = client.createRequest<List<ProjectsStats>>()({
  method: "GET",
  endpoint: "/dashboard/projects",
  options: { timeout: 0 },
});

export const getGroupedDataExtensions = client.createRequest<List<DataExtensionsStats>>()({
  method: "GET",
  endpoint: "/dashboard/data-extensions",
  options: { timeout: 0 },
});

export const getGroupedRemarketyAccounts = client.createRequest<List<RemarketyAccountsStats>>()({
  method: "GET",
  endpoint: "/dashboard/remarkety-accounts",
  options: { timeout: 0 },
});

export const getGroupedMarketoAccounts = client.createRequest<List<MarketoAccountsStats>>()({
  method: "GET",
  endpoint: "/dashboard/marketo-accounts",
  options: { timeout: 0 },
});

export const getGroupedGreenArrowAccounts = client.createRequest<List<GreenArrowAccountsStats>>()({
  method: "GET",
  endpoint: "/dashboard/greenarrow-accounts",
  options: { timeout: 0 },
});

export const getGroupedInsiderAccounts = client.createRequest<List<InsiderAccountsStats>>()({
  method: "GET",
  endpoint: "/dashboard/insider-accounts",
  options: { timeout: 0 },
});
