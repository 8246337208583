import { FC, useRef } from "react";
import { TableRow, TableCell, TextEllipsis, formatDate, getLinkPath, useQuery } from "@epcnetwork/core-ui-kit";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { UnsubscribeJobModel } from "models";
import { UNSUBSCRIBE_JOB_DETAILS_PAGE } from "constants/routes.constants";
import { RootState } from "store";

import { Calendar, Clock } from "assets/icons";

import styles from "./job-item.module.scss";

type UsersListTableRowProps = {
  job: UnsubscribeJobModel;
};

export const JobItem: FC<UsersListTableRowProps> = ({ job }) => {
  const navigate = useNavigate();
  const terminateButtonRef = useRef<HTMLDivElement>(null);

  const { query, stringify } = useQuery();
  const { user } = useSelector((state: RootState) => state.auth);

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const excludeElements = [terminateButtonRef.current];

    const containsExcluded = excludeElements.some((element) => element?.contains(e.target as HTMLElement));
    const isExcluded = excludeElements.some((element) => element === e.target);
    if (!containsExcluded && !isExcluded) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const path = getLinkPath(UNSUBSCRIBE_JOB_DETAILS_PAGE.path, { jobId: job.id });
      // set navigate state to allow going back to with the same query params
      navigate(path, { state: { queryStringified: stringify(query) } });
    }
  };

  const createdAtDate = new Date(job.createdAt);

  return (
    <TableRow id={job.id} onRowClick={handleRowClick} className={styles.tableRow}>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {job.email}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {job.esp}
        </TextEllipsis>
      </TableCell>

      <TableCell className={styles.cellRow}>
        <div className={styles.row}>
          <Calendar />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAtDate, "EEE, MMM dd, yyyy")}
          </TextEllipsis>
        </div>
        <div className={styles.row}>
          <Clock />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAtDate, "HH:mm:ss")}
          </TextEllipsis>
        </div>
      </TableCell>
      <TableCell>
        <div />
      </TableCell>
    </TableRow>
  );
};
