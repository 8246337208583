import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { BasePaginationQuery, BaseSearchQuery, ColumnsType } from "types";
import { DEFAULT_OFFSET } from "constants/query.constants";

export const jobListColumns: ColumnsType = [
  { label: "Creator", required: true, queryKey: "user" },
  { label: "ESP", required: true, queryKey: "esp" },
  { label: "Created at", queryKey: "createdAt" },
  { label: "" },
];

export const TABLE_NAME = "unsubscribe-jobs-table";

export const initialFilters: UseFiltersProps<BasePaginationQuery & BaseSearchQuery> = {
  initialState: {
    search: "",
    limit: "25",
    offset: DEFAULT_OFFSET,
  },
};
