import { TabOption } from "components";
import { Integration } from "types";
import { GreenArrow, Insider, Iterable, Marketo, Remarkety, Salesforce } from "assets";

export const integrationOptions: TabOption<Integration>[] = [
  { value: "iterable", label: "Iterable", icon: <Iterable /> },
  { value: "salesforce", label: "Salesforce", icon: <Salesforce /> },
  { value: "remarkety", label: "Remarkety", icon: <Remarkety /> },
  { value: "marketo", label: "Marketo", icon: <Marketo /> },
  { value: "greenarrow", label: "GreenArrow", icon: <GreenArrow style={{ flexShrink: 0, width: "15px" }} /> },
  { value: "insider", label: "Insider", icon: <Insider /> },
];
