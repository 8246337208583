import { ArrowRightFromLine, CloudDownload, CloudUpload, EraserIcon, ListCollapse } from "lucide-react";
import { UpdateIcon } from "@radix-ui/react-icons";

import { MenuItem } from "./menu.types";
import {
  CREATE_SUPPRESSION_PAGE,
  USERS_LIST_PAGE,
  DASHBOARD_PAGE,
  JOBS_LIST_PAGE,
  AUDIT_LOGS_PAGE,
  ITERABLE_ACCOUNTS_LIST_PAGE,
  SALESFORCE_BUSINESS_UNITS_LIST_PAGE,
  OPTIZMO_COMPANIES_LIST_PAGE,
  MD5_JOBS_PAGE,
  MD5_IMPORT_PAGE,
  MD5_EXPORT_PAGE,
  CREATE_UNSUB_PAGE,
  UNSUBSCRIBE_JOBS_LIST_PAGE,
  REMARKETY_ACCOUNTS_LIST_PAGE,
  AUTOCLEANING_LIST_PAGE,
  MARKETO_ACCOUNTS_LIST_PAGE,
  GREENARROW_ACCOUNTS_LIST_PAGE,
  INSIDER_ACCOUNTS_LIST_PAGE,
} from "constants/routes.constants";
import { PermissionTuple } from "models";

import {
  MenuUpload,
  Users,
  Accounts,
  Dashboard,
  Jobs,
  ActivityLog,
  Iterable,
  Salesforce,
  Optizmo,
  Remarkety,
  Marketo,
  GreenArrow,
  Insider,
} from "assets/icons";

import styles from "./menu.module.scss";

export const links: MenuItem[] = [
  {
    name: DASHBOARD_PAGE.name,
    path: DASHBOARD_PAGE.path,
    icon: <Dashboard />,
    permission: DASHBOARD_PAGE.permission,
    className: "sidebar-dashboard",
  },
  {
    name: "Integrations",
    path: ITERABLE_ACCOUNTS_LIST_PAGE.path,
    icon: <Accounts />,
    permission: ["read", "integrations"] as PermissionTuple,
    className: "sidebar-integrations",
    subMenu: [
      {
        name: "Iterable",
        path: ITERABLE_ACCOUNTS_LIST_PAGE.path,
        icon: <Iterable className={styles.icon} />,
        permission: ITERABLE_ACCOUNTS_LIST_PAGE.permission,
      },
      {
        name: "Salesforce",
        path: SALESFORCE_BUSINESS_UNITS_LIST_PAGE.path,
        icon: <Salesforce />,
        permission: SALESFORCE_BUSINESS_UNITS_LIST_PAGE.permission,
      },
      {
        name: "Optizmo",
        path: OPTIZMO_COMPANIES_LIST_PAGE.path,
        icon: <Optizmo />,
        permission: OPTIZMO_COMPANIES_LIST_PAGE.permission,
      },
      {
        name: "Remarkety",
        path: REMARKETY_ACCOUNTS_LIST_PAGE.path,
        icon: <Remarkety />,
        permission: REMARKETY_ACCOUNTS_LIST_PAGE.permission,
      },
      {
        name: "Marketo",
        path: MARKETO_ACCOUNTS_LIST_PAGE.path,
        icon: <Marketo />,
        permission: MARKETO_ACCOUNTS_LIST_PAGE.permission,
      },
      {
        name: "GreenArrow",
        path: GREENARROW_ACCOUNTS_LIST_PAGE.path,
        icon: <GreenArrow style={{ width: "18px" }} />,
        permission: GREENARROW_ACCOUNTS_LIST_PAGE.permission,
      },
      {
        name: "Insider",
        path: INSIDER_ACCOUNTS_LIST_PAGE.path,
        icon: <Insider />,
        permission: INSIDER_ACCOUNTS_LIST_PAGE.permission,
      },
    ],
  },
  {
    name: "Suppressions",
    path: CREATE_SUPPRESSION_PAGE.path,
    icon: <MenuUpload />,
    permission: ["read", "jobs"] as PermissionTuple,
    className: "sidebar-suppressions",
    subMenu: [
      {
        name: JOBS_LIST_PAGE.name,
        path: JOBS_LIST_PAGE.path,
        icon: <Jobs />,
        permission: JOBS_LIST_PAGE.permission,
        className: "sidebar-jobs",
      },
      {
        name: CREATE_SUPPRESSION_PAGE.name,
        path: CREATE_SUPPRESSION_PAGE.path,
        icon: <MenuUpload />,
        permission: CREATE_SUPPRESSION_PAGE.permission,
        className: "sidebar-suppressions",
      },
    ],
  },
  {
    name: "Remove subscribers",
    path: CREATE_SUPPRESSION_PAGE.path,
    icon: <EraserIcon size={16} style={{ color: "#217ee2" }} />,
    permission: ["read", "jobs"] as PermissionTuple,
    className: "sidebar-suppressions",
    subMenu: [
      {
        name: UNSUBSCRIBE_JOBS_LIST_PAGE.name,
        path: UNSUBSCRIBE_JOBS_LIST_PAGE.path,
        icon: <Jobs />,
        permission: UNSUBSCRIBE_JOBS_LIST_PAGE.permission,
        className: "unsubscribe-jobs",
      },
      {
        name: CREATE_UNSUB_PAGE.name,
        path: CREATE_UNSUB_PAGE.path,
        icon: <EraserIcon size={16} style={{ color: "#217ee2" }} />,
        permission: CREATE_UNSUB_PAGE.permission,
        className: "sidebar-unsubscribe",
      },
    ],
  },
  {
    name: "MD5",
    path: MD5_EXPORT_PAGE.path,
    icon: <ArrowRightFromLine width={16} height={16} color="#217EE2" />,
    permission: MD5_EXPORT_PAGE.permission,
    className: "sidebar-export",
    subMenu: [
      {
        name: MD5_JOBS_PAGE.name,
        path: MD5_JOBS_PAGE.path,
        icon: <ListCollapse width={16} height={16} color="#217EE2" />,
        permission: MD5_JOBS_PAGE.permission,
        className: "sidebar-md5-jobs",
      },
      {
        name: MD5_IMPORT_PAGE.name,
        path: MD5_IMPORT_PAGE.path,
        icon: <CloudUpload width={16} height={16} color="#217EE2" />,
        permission: MD5_IMPORT_PAGE.permission,
        className: "sidebar-md5-import",
      },
      {
        name: MD5_EXPORT_PAGE.name,
        path: MD5_EXPORT_PAGE.path,
        icon: <CloudDownload width={16} height={16} color="#217EE2" />,
        permission: MD5_EXPORT_PAGE.permission,
        className: "sidebar-export",
      },
    ],
  },
  {
    name: AUTOCLEANING_LIST_PAGE.name,
    path: AUTOCLEANING_LIST_PAGE.path,
    icon: <UpdateIcon color="#217EE2" />,
    permission: AUTOCLEANING_LIST_PAGE.permission,
    className: "autocleaning",
  },
  {
    name: AUDIT_LOGS_PAGE.name,
    path: AUDIT_LOGS_PAGE.path,
    icon: <ActivityLog />,
    permission: AUDIT_LOGS_PAGE.permission,
    className: "sidebar-audit",
  },
  {
    name: USERS_LIST_PAGE.name,
    path: USERS_LIST_PAGE.path,
    icon: <Users />,
    permission: USERS_LIST_PAGE.permission,
    className: "sidebar-users",
  },
];
