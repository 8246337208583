import React, { useMemo } from "react";
import { formatDate, notification, TableCell, TableRow, TextEllipsis, Loader } from "@epcnetwork/core-ui-kit";
import { DownloadIcon } from "lucide-react";
import { useSubmit } from "@hyper-fetch/react";

import { Calendar, Clock } from "assets";
import { Md5JobModel } from "models";
import { JobItemStatus } from "components";
import { md5JobDownloadFile } from "api";

import styles from "./md5-job-item.module.scss";

interface Props {
  job: Md5JobModel;
}

export const Md5JobItem: React.FC<Props> = ({ job }) => {
  const { submit, onSubmitSuccess, onSubmitError, submitting } = useSubmit(
    md5JobDownloadFile.setParams({ jobId: job.id }),
  );
  onSubmitSuccess(({ response }) => {
    const element = document.createElement("a");
    element.download = `md5-job-${job.id}.csv`;
    element.href = response;
    element.click();
  });
  onSubmitError(() => {
    notification.error("Error!", "Could not generate a file URL");
  });

  const createdAtDate = job.createdAt;

  const statusMessage = useMemo(() => {
    if (job.status === "error") return job?.error;
    if (job.status === "empty") return "No matches found, output is empty.";
    return null;
  }, [job]);

  return (
    <TableRow id={job.id}>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          <span className={styles.esp}>{job.esp}</span>
        </TextEllipsis>
      </TableCell>

      <TableCell className={styles.cellRow}>
        <div className={styles.row}>
          <Calendar />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAtDate, "EEE, MMM dd, yyyy")}
          </TextEllipsis>
        </div>
        <div className={styles.row}>
          <Clock />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAtDate, "HH:mm:ss")}
          </TextEllipsis>
        </div>
      </TableCell>

      <TableCell>{job.fromList?.name}</TableCell>
      <TableCell>{job.toList?.name}</TableCell>

      <TableCell>
        <JobItemStatus status={job.status} message={statusMessage} />
      </TableCell>

      <TableCell>
        {job.status === "finished" && (
          <button type="button" onClick={submit} className={styles.downloadButton}>
            {!submitting && <DownloadIcon width={16} height={16} />}
            {submitting && <Loader type="clip-loader" />}
          </button>
        )}
      </TableCell>
    </TableRow>
  );
};
