import React from "react";
import cn from "classnames";

import { Skeleton } from "components";
import { Check, Circle, Failed, GreenArrow, Insider, Iterable, Marketo, Remarkety, Salesforce } from "assets";
import { Integration } from "types";

import styles from "./info-box.module.scss";

interface Props {
  integration: Integration;
  description: string;
  completed: string | number;
  failed: string | number;
  active: string | number;
  loading?: boolean;
  className?: string;
}

export const InfoBox: React.FC<Props> = ({
  integration,
  completed,
  failed,
  active,
  description,
  className,
  loading,
}) => {
  const integrationLabel: Record<Integration, { name: string; icon: React.ReactNode }> = {
    iterable: {
      name: "Iterable",
      icon: <Iterable />,
    },
    salesforce: {
      name: "Salesforce",
      icon: <Salesforce />,
    },
    remarkety: {
      name: "Remarkety",
      icon: <Remarkety />,
    },
    marketo: {
      name: "Marketo",
      icon: <Marketo />,
    },
    greenarrow: {
      name: "GreenArrow",
      icon: <GreenArrow />,
    },
    insider: {
      name: "Insider",
      icon: <Insider />,
    },
  };

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.labelWrapper}>
        <span className={styles.label}>{integrationLabel[integration].name}</span>
        {integrationLabel[integration].icon}
      </div>
      <div className={styles.infoWrapper}>
        <div>
          {loading && <Skeleton width="40px" height="30px" className={styles.skeleton} />}
          {!loading && (
            <h2 className={styles.title} data-testid={`${integration}-completed`}>
              {completed}
            </h2>
          )}
          <div className={styles.row}>
            <Check className={styles.completed} />
            <span className={styles.completed}>Succeeded</span>
          </div>
        </div>
        <div>
          {loading && <Skeleton width="40px" height="30px" className={styles.skeleton} />}
          {!loading && (
            <h2 className={styles.title} data-testid={`${integration}-failed`}>
              {failed}
            </h2>
          )}
          <div className={styles.row}>
            <Failed className={styles.failed} />
            <span className={styles.failed}>Failed</span>
          </div>
        </div>
        <div>
          {loading && <Skeleton width="40px" height="30px" className={styles.skeleton} />}
          {!loading && (
            <h2 className={styles.title} data-testid={`${integration}-active`}>
              {active}
            </h2>
          )}
          <div className={styles.row}>
            <Circle className={styles.completed} />
            <span className={styles.completed}>Active</span>
          </div>
        </div>
      </div>
      <span className={styles.description}>{description}</span>
    </div>
  );
};
