import React, { useState } from "react";
import { Pagination, Search, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useFetch } from "@hyper-fetch/react";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useDebounce } from "@better-hooks/performance";

import { InsiderTableItem } from "pages/dashboard/suppressions-status/insider-table/insider-table-item";
import { accountsColumns, TABLE_NAME } from "./insider-table.constants";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { getGroupedInsiderAccounts } from "api";
import { DashboardInsiderData, DashboardListenerEventsKeys, DashboardSocketInstance } from "hooks";
import { List } from "models";
import { InsiderAccountsStatsState } from "api/dashboard/dashboard.types";
import { initialFilters } from "../suppressions-status.constants";
import { getInitialStorageFilters } from "utils";
import { TableError, TableNoContent } from "components";

import styles from "../suppressions-status.module.scss";

interface Props {
  socket: DashboardSocketInstance | null;
}

export const InsiderTable: React.FC<Props> = ({ socket }) => {
  const { debounce } = useDebounce({ delay: 1200 });
  const { query, state, currentState, setValue } = useFilters<BasePaginationQuery & BaseSearchQuery>({
    ...getInitialStorageFilters<BasePaginationQuery & BaseSearchQuery>(TABLE_NAME, initialFilters),
  });

  const [dashboardProjects, setDashboardProjects] = useState<List<InsiderAccountsStatsState> | null>(null);

  const { error, loading, refetch, onSuccess } = useFetch(
    getGroupedInsiderAccounts.setQueryParams({ ...state, ...query }),
  );
  onSuccess(({ response }) => {
    const projects: List<InsiderAccountsStatsState> = {
      ...response,
      data: response.data.map((account) => {
        return {
          ...account,
          active: +account.active,
          completed: +account.completed,
          failed: +account.failed,
        };
      }),
    };
    setDashboardProjects(projects);
  });

  const pagination = usePagination({
    listPayload: dashboardProjects,
    isStateBased: true,
    initialState: currentState,
  });

  useDidUpdate(
    () => {
      const handleProjectUpdate = ({ insiderAccountId, oldState, newState }: DashboardInsiderData) => {
        setDashboardProjects((prevState) => {
          if (!prevState) return prevState;

          const newData = prevState.data.map((project) => {
            if (project.id === insiderAccountId) {
              if (oldState) {
                return {
                  ...project,
                  [oldState]: +project[oldState] > 0 ? +project[oldState] - 1 : project[oldState],
                  [newState]: project[newState] + 1,
                };
              }
              return { ...project, [newState]: project[newState] + 1 };
            }
            return project;
          });

          return { ...prevState, data: newData };
        });
      };

      if (socket) {
        socket.on<DashboardListenerEventsKeys>("dashboardInsiderAccountUpdate", handleProjectUpdate);

        return () => {
          socket.off<DashboardListenerEventsKeys>("dashboardInsiderAccountUpdate", handleProjectUpdate);
        };
      }
    },
    [socket],
    true,
  );

  return (
    <div>
      <div className={styles.filtersWrapper}>
        <div className={styles.searchWrapper}>
          <Search
            searchValue={query.search}
            setSearch={(value) => {
              debounce(() => setValue("search")(value));
            }}
            onClearClick={() => setValue("search")("")}
            width="small"
            className={styles.search}
          />
        </div>
      </div>
      <Table
        entityName={TABLE_NAME}
        columns={accountsColumns}
        list={dashboardProjects?.data}
        error={error?.message}
        refresh={() => refetch()}
        loading={loading}
        resetColumnsOnMount={false}
        className={styles.table}
        contentClassName={styles.tableContent}
        customNoContent={<TableNoContent />}
        customError={<TableError description={error?.message} />}
        row={(data, index) => <InsiderTableItem data={data} index={index} />}
      />
      <div className={styles.pagination}>
        <Pagination
          {...pagination}
          onPageChange={(page, offset) => {
            pagination.onPageChange(page, offset);
            setValue("offset")(offset);
          }}
          onElementsPerPageChange={(value) => {
            pagination.onElementsPerPageChange(value);
            setValue("limit")(value);
          }}
        />
      </div>
    </div>
  );
};
