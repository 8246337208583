import React from "react";
import { useFormikContext } from "formik";
import { Label } from "@epcnetwork/core-ui-kit";

import { TabOption, Tabs } from "components";
import { SalesforceUnsub } from "./salesforce/salesforce-unsub";
import { IterableUnsub } from "./iterable/iterable-unsub";
import { RemarketyUnsub } from "./remarkety/remarkety-unsub";
import { MarketoUnsub } from "./marketo/marketo-unsub";
import { InsiderUnsub } from "./insider/insider-unsub";
import { GreenArrowUnsub } from "./green-arrow/green-arrow-unsub";
import {
  initialSalesforceData,
  initialIterableData,
  initialRemarketyData,
  initialMarketoData,
  initialGreenArrowData,
  initialInsiderData,
} from "../create-unsub.constants";
import { UnsubscribeFormData } from "api";
import { GreenArrow, Insider, Iterable, Marketo, Remarkety, Salesforce } from "assets";
import { Integration } from "types";

export const UnsubListSelect: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<UnsubscribeFormData>();

  const handleTabChange = async ({ value }: TabOption<Integration>) => {
    await setFieldValue("data.esp", value);
    if (value === "iterable") await setFieldValue("data.properties", initialIterableData);
    if (value === "salesforce") await setFieldValue("data.properties", initialSalesforceData);
    if (value === "remarkety") await setFieldValue("data.properties", initialRemarketyData);
    if (value === "marketo") await setFieldValue("data.properties", initialMarketoData);
    if (value === "greenarrow") await setFieldValue("data.properties", initialGreenArrowData);
    if (value === "insider") await setFieldValue("data.properties", initialInsiderData);
  };

  const options: TabOption<Integration>[] = [
    { label: "Iterable", value: "iterable", icon: <Iterable /> },
    { label: "Salesforce", value: "salesforce", icon: <Salesforce /> },
    { label: "Remarkety", value: "remarkety", icon: <Remarkety /> },
    { label: "Marketo", value: "marketo", icon: <Marketo /> },
    { value: "greenarrow", label: "GreenArrow", icon: <GreenArrow style={{ flexShrink: 0, width: "15px" }} /> },
    { label: "Insider", value: "insider", icon: <Insider /> },
  ];

  return (
    <div>
      <Label text="Select ESP" isInputLabel />
      <Tabs selected={values.data.esp} onChange={handleTabChange} options={options} />

      {values.data.esp === "iterable" && <IterableUnsub />}
      {values.data.esp === "salesforce" && <SalesforceUnsub />}
      {values.data.esp === "remarkety" && <RemarketyUnsub />}
      {values.data.esp === "marketo" && <MarketoUnsub />}
      {values.data.esp === "greenarrow" && <GreenArrowUnsub />}
      {values.data.esp === "insider" && <InsiderUnsub />}
    </div>
  );
};
