import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { BasePaginationQuery, BaseSearchQuery, ColumnsType } from "types";
import { DEFAULT_OFFSET } from "constants/query.constants";

export const TABLE_NAME = "suppression-values";

export const initialFilters: UseFiltersProps<BasePaginationQuery & BaseSearchQuery> = {
  initialState: {
    search: "",
    offset: DEFAULT_OFFSET,
    limit: "25",
  },
};

export const unsubscribeValuesColumns: ColumnsType = [
  { label: "Email", required: true, queryKey: "value" },
  { label: "Status", required: true, queryKey: "status" },
  { label: "" },
];
