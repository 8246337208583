import { number, object, Schema, string } from "yup";

import { requiredFieldText } from "constants/form.constants";
import {
  UnsubscribeEspData,
  IterableUnsubscribeData,
  SalesforceUnsubscribeData,
  RemarketyUnsubscribeData,
  MarketoUnsubscribeData,
  GreenArrowUnsubscribeData,
  InsiderUnsubscribeData,
} from "api";

export const MAX_UNSUBSCRIBE_EMAILS = 10;

export const initialIterableData: IterableUnsubscribeData = {
  accountId: 0,
  projectId: 0,
  listId: 0,
};

export const initialSalesforceData: SalesforceUnsubscribeData = {
  businessUnitId: 0,
  listId: null,
};

export const initialRemarketyData: RemarketyUnsubscribeData = {
  remarketyAccountId: 0,
};

export const initialMarketoData: MarketoUnsubscribeData = {
  marketoAccountId: 0,
};

export const initialGreenArrowData: GreenArrowUnsubscribeData = {
  greenarrowAccountId: 0,
  listId: 0,
};

export const initialInsiderData: InsiderUnsubscribeData = {
  insiderAccountId: 0,
};

export const initialEspValues: UnsubscribeEspData = {
  esp: "iterable",
  properties: initialIterableData,
};

const iterableEspSchema: Schema<IterableUnsubscribeData> = object().shape({
  accountId: number().min(1, "Account is required").required("Account is required"),
  projectId: number().min(1, "Account is required").required("Project is required"),
  listId: number().min(1, "Account is required").required("List is required"),
});

const salesforceEspSchema: Schema<SalesforceUnsubscribeData> = object().shape({
  businessUnitId: number().min(1, "Business unit is required").required("Business unit is required"),
  listId: number().required(requiredFieldText),
});

const remarketyEspSchema: Schema<RemarketyUnsubscribeData> = object().shape({
  remarketyAccountId: number().min(1, "Remarkety account is required").required("Remarkety account is required"),
});

const marketoEspSchema: Schema<MarketoUnsubscribeData> = object().shape({
  marketoAccountId: number().min(1, "Marketo account is required").required("Marketo account is required"),
});

const greenArrowEspSchema: Schema<GreenArrowUnsubscribeData> = object().shape({
  greenarrowAccountId: number().min(1, "GreenArrow account is required").required("GreenArrow account is required"),
  listId: number().min(1, "GreenArrow account is required").required("GreenArrow account is required"),
});

const insiderEspSchema: Schema<InsiderUnsubscribeData> = object().shape({
  insiderAccountId: number().min(1, "Insider account is required").required("Insider account is required"),
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const espUnsubValidationSchema: Schema<UnsubscribeEspData> = object().shape({
  esp: string().oneOf(["iterable", "salesforce", "remarkety", "marketo"]).required(requiredFieldText),
  properties: object()
    .when("esp", ([esp]) => {
      if (esp === "iterable") return iterableEspSchema;
      if (esp === "salesforce") return salesforceEspSchema;
      if (esp === "remarkety") return remarketyEspSchema;
      if (esp === "marketo") return marketoEspSchema;
      if (esp === "greenarrow") return greenArrowEspSchema;
      if (esp === "insider") return insiderEspSchema;
      return iterableEspSchema;
    })
    .required(requiredFieldText),
});
