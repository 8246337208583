import React, { useState } from "react";
import { useQuery } from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";
import cn from "classnames";

import { Tabs, TabOption } from "components";
import { Integration } from "types";
import { integrationOptions } from "utils";
import { IterableTable } from "./iterable-table/iterable-table";
import { SalesforceTable } from "./salesforce-table/salesforce-table";
import { RemarketyTable } from "./remarkety-table/remarkety-table";
import { MarketoTable } from "./marketo-table/marketo-table";
import { InsiderTable } from "./insider-table/insider-table";
import { GreenArrowTable } from "./green-arrow-table/green-arrow-table";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "constants/query.constants";
import { DashboardSocketInstance } from "hooks";

import styles from "./suppressions-status.module.scss";

type Props = {
  socket: DashboardSocketInstance | null;
};

export const SuppressionsStatus: React.FC<Props> = ({ socket }) => {
  const { setQueryParams } = useQuery();

  const [selectedIntegration, setSelectedIntegration] = useState<Integration>("iterable");

  useDidUpdate(() => {
    setQueryParams({ search: "", offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT, order: "" });
  }, [selectedIntegration]);

  const handleIntegrationChange = (option: TabOption<Integration>) => {
    setSelectedIntegration(option.value);
  };

  const title: Record<Integration, string> = {
    iterable: "Iterable projects",
    salesforce: "Salesforce data extensions",
    remarkety: "Remarkety accounts",
    marketo: "Marketo accounts",
    greenarrow: "GreenArrow accounts",
    insider: "Insider accounts",
  };

  return (
    <div className={cn(styles.container, "dashboard-suppressions-statuses")}>
      <div>
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <span className={styles.label}>Suppressions status by {title[selectedIntegration]}</span>
            <span className={styles.subtitle}>in the last 24 hours</span>
          </div>

          <Tabs
            options={integrationOptions}
            selected={selectedIntegration}
            onChange={handleIntegrationChange}
            className={cn(styles.tabs, "dashboard-status-tabs")}
          />
        </div>

        <div className={styles.content}>
          {selectedIntegration === "iterable" && <IterableTable socket={socket} />}
          {selectedIntegration === "salesforce" && <SalesforceTable socket={socket} />}
          {selectedIntegration === "remarkety" && <RemarketyTable socket={socket} />}
          {selectedIntegration === "marketo" && <MarketoTable socket={socket} />}
          {selectedIntegration === "greenarrow" && <GreenArrowTable socket={socket} />}
          {selectedIntegration === "insider" && <InsiderTable socket={socket} />}
        </div>
      </div>
    </div>
  );
};
