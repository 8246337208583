import { ColumnsType } from "types";

export const TABLE_NAME = "iterable-by-projects";

export const projectColumns: ColumnsType = [
  { label: "Project", required: true, queryKey: "name" },
  { label: "Integration" },
  { label: "Account", queryKey: "account" },
  { label: "Succeeded", required: true, queryKey: "completed" },
  { label: "Failed", required: true, queryKey: "failed" },
  { label: "Active", required: true, queryKey: "active" },
];
