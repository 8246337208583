import React, { useRef, useState } from "react";
import { Form, Formik } from "formik";
import { Button, FormField, notification } from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useSubmit } from "@hyper-fetch/react";
import { useNavigate } from "react-router-dom";

import { initialValues, validationSchema } from "./unsub-manually.constants";
import { MAX_UNSUBSCRIBE_EMAILS } from "../create-unsub.constants";
import { createUnsubscribe } from "api";
import { UNSUBSCRIBE_JOBS_LIST_PAGE } from "constants/routes.constants";
import { UnsubManuallyFormData } from "./unsub-manually.types";
import { UnsubListSelect } from "../unsub-list-select/unsub-list-select";

import styles from "./unsub-manually.module.scss";

export const UnsubManually: React.FC = () => {
  const navigate = useNavigate();
  const textareaRef = useRef<(HTMLInputElement & HTMLTextAreaElement) | null>(null);

  const [textareaValue, setTextareaValue] = useState<string>("");

  const { submit, onSubmitSuccess, onSubmitError, submitting } = useSubmit(createUnsubscribe);
  onSubmitSuccess(() => {
    notification.success("Success!", "Emails were added to queue.");
    navigate(UNSUBSCRIBE_JOBS_LIST_PAGE.path);
  });
  onSubmitError(() => {
    notification.error("Error!", "Could not create a job.");
  });

  useDidUpdate(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "100px";
      const scrollHeight = textareaRef.current.scrollHeight;

      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [textareaRef, textareaValue]);

  const handleSubmit = async (values: UnsubManuallyFormData) => {
    const { emails, data } = values;
    const filteredEmails = emails.split(/\r?\n/).filter(Boolean);

    await submit({ data: { emails: filteredEmails, data } });
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        Paste emails you want to add as suppression separated by a new line (maximum of{" "}
        <strong>{MAX_UNSUBSCRIBE_EMAILS}</strong> emails at once).
      </h2>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnMount>
        {({ isValid }) => {
          return (
            <Form>
              <div className={styles.wrapper}>
                <FormField
                  name="emails"
                  type="textarea"
                  label="Emails"
                  forwardRef={textareaRef}
                  onChange={setTextareaValue}
                  inputClassName={styles.textarea}
                  className="emails-textarea"
                />

                <div>
                  <UnsubListSelect />

                  <div className={styles.buttons}>
                    <Button type="submit" disabled={!isValid} loading={submitting}>
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
