import { FC } from "react";
import { TableRow, TableCell, TextEllipsis } from "@epcnetwork/core-ui-kit";

import { UnsubscribeValueModel } from "models";
import { JobItemStatus } from "components";

import styles from "./unsubscribe-value-item.module.scss";

type SuppressionValueProps = {
  index: number;
  value: UnsubscribeValueModel;
};

export const UnsubscribeValueItem: FC<SuppressionValueProps> = ({ index, value }) => {
  return (
    <TableRow id={index} className={styles.row}>
      <TableCell className={styles.name}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {value.value}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          <JobItemStatus status={value.status} message={value?.error} />
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <></>
      </TableCell>
    </TableRow>
  );
};
